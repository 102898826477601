$primary-color: #3aaea9;
$text-color: #333333;
$shadow-color: #eeeeee;

$circle-diameter: 250px;

@mixin center-circle {
  position: absolute;
  top: 240px;
  left: 50%;
  transform: translateX(-50%);
  width: $circle-diameter;
  height: $circle-diameter;
  border-radius: 50%;
}

@mixin text-white {
  color: white;
}

@mixin white-button {
  background-color: white;
  color: $primary-color;
  height: 32px;
  width: 140px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 1px 0 $shadow-color;
  cursor: pointer;
  &:hover {
    box-shadow: none;
    background-color: $shadow-color;
  }
  transition: background-color 0.3s, box-shadow 0.3s;
}

body {
  margin: 0;
  background-color: $primary-color;
  font-family: "Oxygen", "Open Sans", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  max-width: 100vw;
}