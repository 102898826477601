@import 'index.scss';

@keyframes greyToWhite {
    0% {
        background-color: #333333;
    }
    100% {
        background-color: white;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.main {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 600px;
    &--porthole {
        @include center-circle;
        box-shadow: 0 0 0 9999px $primary-color;
        pointer-events: none;
        z-index: -1;
    }
    &--title {
        margin: 80px 0 8px;
    }
    &--subtitle {
        margin: 0 auto;
        font-size: 16px;
        padding: 0 24px;
        line-height: 1.25;
        max-width: 350px;
        box-sizing: border-box;
    }
    &--title, &--subtitle {
        @include text-white;
        text-align: center;
        font-weight: normal;
    }
    &--image {
        @include center-circle;
        overflow: hidden;
        z-index: -2;
        transition: all 0.7s ease-in-out;
        img {
            width: 100%;
            margin-top: -40px;
        }
        &__hidden {
            transform: translateX(100%);
        }
    }
    &--inner {
        @include center-circle;
        z-index: -3;
        animation-name: greyToWhite;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        &__visible {
            z-index: 0;
            transition-delay: 0.7s;
        }
        .main-links {
            display: flex;
            flex-wrap: wrap;
            padding: 60px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
        .main--link {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 50%;
            height: 50%;
            box-sizing: border-box;
            cursor: pointer;
            &, &:hover, &:focus, &:visited {
                color: $text-color;
            }
            &::after {
                position: absolute;
                z-index: 0;
                left: 18px;
                bottom: 10px;
                height: 3px;
                width: 0;
                background-color: $primary-color;
                content: "";
                transition: width 0.3s;
            }
            &:hover::after {
                width: 30px;
            }
            .main--link-icon {
                opacity: 0;
                animation-name: fadeIn;
                animation-duration: 1ms;
                animation-fill-mode: forwards;
                animation-delay: 1s;
            }
        }
    }
    &--button {
        @include white-button;
        margin-top: 330px;
    }
}